import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { PdfField, FieldType, Recipients } from '../document/document.types';
import { Subject } from 'rxjs';
import { List } from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  private _fieldsSubject = new BehaviorSubject<PdfField[]>([]);
  public fields$ = this._fieldsSubject.asObservable();

  private _digitalFieldSubject = new BehaviorSubject<boolean>(false);
  public digitalField$ = this._digitalFieldSubject.asObservable();

  private recipientsListSubject = new BehaviorSubject<List<Recipients>>(null);
  public recipientsList$ = this.recipientsListSubject.asObservable();

  public batchCountSource = new BehaviorSubject<number>(1);
  batchCount = this.batchCountSource.asObservable();

  public imagesSource = new BehaviorSubject<any[]>([]);
  currentImages = this.imagesSource.asObservable();

  private totalBatchSource = new BehaviorSubject<number>(0);
  totalBatch = this.totalBatchSource.asObservable();

  private documentSource = new BehaviorSubject<any>(null);
  documentId = this.documentSource.asObservable();

  private totalImagesSource = new BehaviorSubject<number>(0);
  totalImages = this.totalImagesSource.asObservable();

  public documentNameSource = new BehaviorSubject<string>('');
  documentName = this.documentNameSource.asObservable();

  private showAnotationSource = new BehaviorSubject<boolean>(true);
  showAnotation = this.showAnotationSource.asObservable();

  private selectedLanguageSource = new BehaviorSubject<string>('');
  selectedLanguage = this.selectedLanguageSource.asObservable();

  private panelSource = new BehaviorSubject<any>(null);
  fieldPanel = this.panelSource.asObservable();

  private workflowSource = new BehaviorSubject<any>(null);
  workflow = this.workflowSource.asObservable();

  // getting data for csc v2 flow
  private workflowIdSource = new BehaviorSubject<any>(null);
  workflowId = this.workflowIdSource.asObservable();

  private fieldIdSource = new BehaviorSubject<any>(null);
  fieldId = this.fieldIdSource.asObservable();

  private fieldValueSource = new BehaviorSubject<any>(null);
  fieldValue = this.fieldValueSource.asObservable();

  private fieldQualifierSource = new BehaviorSubject<any>(null);
  fieldQualifier = this.fieldQualifierSource.asObservable();

  public qesFlowTriggerSource = new BehaviorSubject<any>(null);
  qesFlowTrigger$ = this.qesFlowTriggerSource.asObservable();

  public usbTokenFlowTriggerSource = new BehaviorSubject<any>(null);
  usbTokenFlowTrigger$ = this.usbTokenFlowTriggerSource.asObservable();

  private signCScResponseSource = new Subject<any>();
  signCScResponse = this.signCScResponseSource.asObservable();

  triggerQesFlow(workflowId: any, fieldId: any, fieldImage: any, server: any, qualifier: any) {
    this.qesFlowTriggerSource.next({ workflowId, fieldId, fieldImage, server, qualifier });
  }

  triggerUsbTokenFlow(workflowId: any, fieldId: any, fieldImage: any, signerAppUrl: any) {
    this.usbTokenFlowTriggerSource.next({ workflowId, fieldId, fieldImage, signerAppUrl });
  }

  setSignCScResponse(response: any) {
    this.signCScResponseSource.next(response);
  }


  constructor(private _httpClient: HttpClient) { }

  private saveFieldSubject = new Subject<{ field: PdfField, pageNumber: number }>();
  saveField$ = this.saveFieldSubject.asObservable();

  private signDocumentSubject = new Subject<PdfField | null>();
  signDocument$ = this.signDocumentSubject.asObservable();

  triggerSignDocument(field: PdfField) {
    this.signDocumentSubject.next(field);
  }

  triggerSaveField(field: PdfField, pageNumber: number): void {
    this.saveFieldSubject.next({ field, pageNumber });
  }

  updateBatchCount(count: number): void {
    this.batchCountSource.next(count);
  }

  updateImages(images: any[]): void {
    this.imagesSource.next(images);
  }

  setDigitalField(digitalField: boolean): void {
    this._digitalFieldSubject.next(digitalField);
  }

  setFieldArray(fields: PdfField[]): void {
    this._fieldsSubject.next(fields);
  }

  setRecipientsList(recipients: List<Recipients>): void {
    this.recipientsListSubject.next(recipients);
  }

  removeFieldFromPanel(id: string): void {

    const fields = this._fieldsSubject.value;

    const index = fields.findIndex((field) => field.id === id);
    if (index !== -1) {
      fields.splice(index, 1);
      this._fieldsSubject.next(fields);
    }
  }

  addFieldToPanel(field: any): void {
    this.panelSource.next(field);
  }

  addWorkflowToPanel(workflow: any): void {
    this.workflowSource.next(workflow);
  }

  setWorkflowId(workflowId: any): void {
    this.workflowIdSource.next(workflowId);
  }

  setFieldId(fieldId: any): void {
    this.fieldIdSource.next(fieldId);
  }

  setFieldValue(fieldValue: any): void {
    this.fieldValueSource.next(fieldValue);
  }

  setFieldQualifier(qualifier: any): void {
    this.fieldQualifierSource.next(qualifier);
  }

  setSelectedFieldArray(fields: PdfField[]): void {
    this.selectedFieldArraySource.next(fields);
  }

  setBatch(batch: number): void {
    this.totalBatchSource.next(batch);
  }

  setDocumentId(id: any): void {
    this.documentSource.next(id);
  }


  public selectedFieldArraySource = new BehaviorSubject<PdfField[] | null>(null);
  selectedFieldArray$ = this.selectedFieldArraySource.asObservable();

  setTotalImages(total: number): void {
    this.totalImagesSource.next(total);
  }

  setDocumentName(name: string): void {
    this.documentNameSource.next(name);
  }

  setShowAnotation(show: boolean) {
    this.showAnotationSource.next(show);
  }

  setSelectedLanguage(lang: string): void {
    this.selectedLanguageSource.next(lang);
  }
}
